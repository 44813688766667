import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { Constants } from "src/app/constants/constants";
import { CustomImage } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "src/app/services/title.service";
import { CustomImageService } from "../custom-image.service";

@Component({
    selector: "app-custom-image-list",
    templateUrl: "./custom-image-list.component.html"
})
export class CustomImageListComponent implements OnInit {
    private titleService = inject(TitleService);
    private translate = inject(TranslateService);
    private customImageService = inject(CustomImageService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private router = inject(Router);
    customImages$ = this.customImageService.customImages;
    refreshing = false;
    urls = Constants.urls;
    tableColumnsSchema: TableSchema<CustomImage>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 200,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name,
            textValue: row => row.name
        },
        {
            header: this.translate.instant("REGION"),
            columnDef: "region",
            width: 200,
            visible: true,
            sticky: 2,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.region,
                row => row.region,
                () => true
            ),
            sortBy: row => row.region,
            textValue: row => row.region
        },
        {
            header: this.translate.instant("VERSION"),
            columnDef: "version",
            width: 200,
            visible: true,
            sticky: 3,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.nvidia_cuda_version,
                row => row.nvidia_cuda_version,
                () => true
            ),
            sortBy: row => row.nvidia_cuda_version,
            textValue: row => row.nvidia_cuda_version
        },
        {
            header: this.translate.instant("ARCHITECTURE"),
            columnDef: "architecture",
            width: 200,
            visible: true,
            sticky: 4,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.architecture,
                row => row.architecture,
                () => true
            ),
            sortBy: row => row.architecture,
            textValue: row => row.architecture
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            visible: true,
            sticky: 5,
            align: "right",
            width: 60,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                editRef: row => this.edit(row),
                deleteRef: row => this.delete(row)
            })
        }
    ];

    ngOnInit() {
        this.titleService.setTitle(this.translate.instant("AWS_AMIS"));
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.customImageService.refreshCustomImages());
        this.refreshing = false;
    }

    async delete(customImage: CustomImage) {
        await this.modalService.confirm(
            "DELETE",
            "AWS_AMI",
            async () => {
                const result = await this.customImageService.deleteCustomImage(customImage);
                if (result) {
                    this.mixpanelService.sendEvent("delete customImage", { id: customImage.id });
                } else {
                    return false;
                }
            },
            customImage.name
        );
    }

    edit(customImage: CustomImage) {
        this.router.navigate([Constants.urls.configuration.customImage, customImage.id, "edit"]);
    }
}
