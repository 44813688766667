<content-loading-animation *ngIf="loading"></content-loading-animation>
<app-form-wrapper [formGroup]="form" *ngIf="!loading" [titleMsg]="'AWS_AMI' | translate" [isEdit]="isEdit" [isClone]="isClone" [hasTourStartButton]="false">
    <section id="info">
        <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
        <!-- Name -->
        <app-input-generic-field
            [formControl]="form.controls.name"
            [textFieldName]="'NAME' | translate"
            [isParentFormSubmitted]="submitted"
            [checkDuplicateNames]="customImageNames$ | async"
            type="text"
        ></app-input-generic-field>

        <!-- ami_id -->
        <app-input-generic-field
            [formControl]="form.controls.ami_id"
            [textFieldName]="'AMI_ID' | translate"
            [isParentFormSubmitted]="submitted"
            type="text"
            patternErrorMessage="should be 'ami-XXXX'"
        ></app-input-generic-field>

        <!-- Region  -->
        <app-select-generic-field
            elementId="region"
            title="{{ 'REGION' | translate }}"
            valueToBind="value"
            [formControl]="form.controls.region"
            placeHolder="{{ 'SELECT' | translate }} {{ 'AWS_REGION' | translate }}"
            [isParentFormSubmitted]="submitted"
            [isRestartRequiredTip]="isEdit"
            [items]="constants.awsRegions"
        >
        </app-select-generic-field>

        <!-- Nvidia Cudas Version -->
        <app-radio-input
            label="Nvidia CUDA {{ 'VERSION' | translate }}"
            [isParentFormSubmitted]="submitted"
            [inputKeyAndValues]="nvidiaCudaVersionTypes"
            [formControl]="form.controls.nvidia_cuda_version"
        ></app-radio-input>

        <!-- architecture -->
        <app-radio-input
            label="{{ 'ARCHITECTURE' | translate }}"
            [isParentFormSubmitted]="submitted"
            [inputKeyAndValues]="architectureTypes"
            [formControl]="form.controls.architecture"
        ></app-radio-input>
    </section>

    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <app-zx-form-bottom-buttons
        (submit)="onSubmit()"
        [isFormValid]="form.valid"
        [(submitted)]="submitted"
        [isSaving]="saving"
        [isEdit]="isEdit || isClone"
        [cancelUrlNavigate]="constants.urls.configuration.customImage"
    >
    </app-zx-form-bottom-buttons>
</app-form-wrapper>
