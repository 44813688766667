<div class="panel-container">
    <div id="left-container" class="left-container flex-basis-0" #leftContainer>
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 title="{{ 'AWS_AMI' | translate }}" translate>{{ "AWS_AMI" | translate }}</h1>
            </div>
            <div class="actions">
                <button type="button" class="btn btn-outline-primary me-2" routerLink="/{{ urls.configuration.customImage }}/new">
                    <fa-icon icon="plus" size="sm"></fa-icon>
                    <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                    <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                    <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                </button>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel>
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    tableName="customImage"
                    [displayTableName]="'AWS_AMI' | translate"
                    [data]="customImages$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [showReport]="false"
                    [showSelectionCheckbox]="false"
                    [selectable]="false"
                    [hoverable]="false"
                >
                </app-table-list>
            </div>
        </div>
    </div>
</div>
