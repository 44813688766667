import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomImageComponent } from "./custom-image.component";
import { CustomImageFormComponent } from "./custom-image-form/custom-image-form.component";
import { CustomImageListComponent } from "./custom-image-list/custom-image-list.component";
import { CustomImageRouting } from "./custom-image.routing";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "src/app/modules/font-awesome/font-awesome.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        MatTooltipModule,
        TourMatMenuModule,
        CustomImageRouting
    ],
    declarations: [CustomImageComponent, CustomImageFormComponent, CustomImageListComponent]
})
export class CustomImageModule {}
