import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";
import { CustomImage } from "src/app/models/shared";
import { CustomImageService } from "../custom-image.service";

@Injectable({
    providedIn: "root"
})
export class CustomImageListResolverService implements Resolve<CustomImage[]> {
    constructor(private customImageService: CustomImageService) {}

    resolve(): Observable<CustomImage[]> | Observable<never> {
        return new Observable((observe: Subscriber<CustomImage[]>) => {
            this.customImageService.refreshCustomImages().subscribe((apiKeys: CustomImage[]) => {
                observe.next(apiKeys);
                observe.complete();
            });
        });
    }
}
